import React from 'react';

import Link from 'next/link';
import { useWindowSize } from 'usehooks-ts';

export interface PosterDesignElements {
  posterGradient: string;
  posterText: string;
  posterImage: string;
  posterDescription: string;
  posterShadow: string;
}

interface PosterProps {
  posterDesignElements: PosterDesignElements;
  isCompact: boolean;
  scrollRef: React.RefObject<HTMLDivElement>;
  handleNavigation: () => void;
  shopLink: string;
}

const ShopOfMonthCarouselPoster: React.FC<PosterProps> = ({
  posterDesignElements,
  shopLink,
  handleNavigation,
}) => {
  const windowSize = useWindowSize();

  return (
    // console.log(posterDesignElements);
    <>
      <Link
        href={`${shopLink}`}
        passHref
        onClick={handleNavigation}
        className={`md:hidden w-11/12 lg:h-[400px] sm:w-1/3 transform lg:scale-110 lg:w-[350px] sm:aspect-11/14  relative flex items-center perspective cursor-pointer z-20 ${
          windowSize.width < 1024 && windowSize.width > 600 && 'hidden'
        }`}
      >
        {/* 3D TRANSFORMED BACKGROUND */}
        <div
          className={`absolute w-11/12 md:w-[300px] ml-4 mt-4 inset-0 mx-auto bg-gradient-to-br preserve-3d rotate-n-push-z z-[-1] rounded-3xl sm:block transition-transform shadow-2xl
      ${posterDesignElements.posterGradient} ${posterDesignElements.posterShadow}`}
        ></div>

        {/* Card elements */}
        <div className="flex flex-row md:flex-col lg:flex-col justify-evenly w-full h-full relative perspective">
          <img
            loading="lazy"
            src={posterDesignElements.posterImage}
            alt={`Image of tayara's carousel poster : ${posterDesignElements.posterText}`}
            className="w-1/2 h-1/2 md:w-full md:h-fit lg:w-full lg:h-fit relative top-6 md:top-2 lg:top-0 z-10"
          ></img>

          {/* <Text
            className="text-white text-[1rem] lg:text-[1.8rem] mr-5 my-2 lg:my-0  font-extrabold text-center self-end md:self-center lg:self-center"
            text={posterDesignElements.posterText}
          /> */}
        </div>
      </Link>
    </>
  );
};
export default ShopOfMonthCarouselPoster;
