import React from 'react';

import { FaInfoCircle } from 'react-icons/fa';
import { MdOutlineCheckCircle, MdOutlineCancel } from 'react-icons/md';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const AgeVerificationModal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0  flex items-center justify-center  !z-[9999]">
      <div className="bg-white rounded-lg shadow-xl p-6 w-11/12 max-w-md mx-auto relative">
        {/* Info Icon at the top */}
        <div className="absolute top-4 left-4">
          <FaInfoCircle size={24} className="text-blue-500" />
        </div>
        <h2 className="text-2xl font-bold mb-4 text-center">
          {"Vérification de l'âge"}
        </h2>
        <p className="text-gray-700 text-center mb-6">
          {
            ' Vous devez avoir plus de 18 ans pour consulter cette annonce. Avez-vous plus de 18 ans ?'
          }
        </p>
        <div className="flex justify-center space-x-4">
          <button
            onClick={onConfirm}
            className="flex items-center px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600"
          >
            <MdOutlineCheckCircle size={20} className="mr-2" />
            {"Oui, j'ai plus de 18 ans"}
          </button>
          <button
            onClick={onClose}
            className="flex items-center px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
          >
            <MdOutlineCancel size={20} className="mr-2" />
            Non
          </button>
        </div>
      </div>
    </div>
  );
};

export default AgeVerificationModal;
