export const cgKeys = new Map([
  ['tayara meuble monastir', 'meuble monastir'],
  ['dressing occasion tayara tunisie', 'dressing'],
  ['electroménager brandt algérie prix', 'electroménager brandt'],
  ['meubles tayara', 'meubles'],
  ['tayara tn meuble occasion', 'meuble'],
  ['tayara meubles occasion sfax', 'meubles sfax'],
  ['tayara lit bébé occasion', 'lit bébé'],
  ['tayara meuble occasion tunis', 'meuble tunis'],
  ['site tayara meuble', 'site meuble'],
  ['tayara meuble occasion tunisie', 'meuble'],
  ['tayara meuble cuisine occasion', 'meuble cuisine'],
  ['salon en bois occasion en tunisie tayara', 'salon en bois'],
  ['tayara meuble tunisie', 'meuble'],
  ['tayara meuble sousse', 'meuble sousse'],
  ['meubles occasions tayara tunis', 'meubless tunis'],
  ['tayara meuble occasion ariana', 'meuble ariana'],
  ['meuble occasion tunisie tayara', 'meuble'],
  ['meuble a vendre tayara', 'meuble a vendre'],
  ['electro tunisie prix', 'electro'],
  ['meuble et decoration tayara', 'meuble et decoration'],
  ['tayara meubles ben arous', 'meubles ben arous'],
  ['meuble occasion sfax tayara', 'meuble sfax'],
  ['vente électroménager tunisie en ligne', 'vente électroménager en ligne'],
  ['tayara monastir meublé', 'monastir meublé'],
  ['tayara chambre à coucher occasion', 'chambre à coucher'],
  ['tayara tn sfax meuble', 'sfax meuble'],
  ['tayara meuble occasion sfax', 'meuble sfax'],
  ['www tayara tn meuble', 'meuble'],
  ['tayara tn meuble occasion ariana', 'meuble ariana'],
  ['tayara meuble sfax', 'meuble sfax'],
  ['tayara tn sousse meuble', 'sousse meuble'],
  ['tayara meuble cuisine', 'meuble cuisine'],
  ['tayara meuble tunis', 'meuble tunis'],
  [
    'machine a laver grand montréal acheter et vendre appareils électroménagers laveuses sécheuses',
    'machine a laver grand montréal acheter et vendre appareils électroménagers laveuses sécheuses',
  ],
  ['tayara dressing occasion', 'dressing'],
  ['tayara meuble occasion sousse', 'meuble sousse'],
  ['element de cuisine tayara', 'element de cuisine'],
  ['tayara meuble occasion près de ben arous', 'meuble près de ben arous'],
  ['meuble tayara ariana', 'meuble ariana'],
  ['qualité prix raisonnable électroménager', 'électroménager'],
  ['vente electromenager', 'vente electromenager'],
  ['tayara tn meuble', 'meuble'],
  ['tayara meuble salon', 'meuble salon'],
  ['vente meuble occasion tunisie tayara', 'vente meuble'],
  ['tayara table et chaise occasion', 'table et chaise'],
  ['tayara table de cuisine', 'table de cuisine'],
  ['vente électroménager', 'vente électroménager'],
  ['vente électroménager tunisie', 'vente électroménager'],
  ['tayara meuble occasion près de marsa', 'meuble près de marsa'],
  ['tayara meuble occasion', 'meuble'],
  ['tayara tn mobilier', 'mobilier'],
  ['tayara meuble salon ariana', 'meuble salon ariana'],
  ['lit occasion tayara', 'lit'],
  ['tayara annonce meuble', 'annonce meuble'],
  ['électroménager prix tunisie', 'électroménager'],
  ['tayara meubles', 'meubles'],
  ['meuble tayara tunis', 'meuble tunis'],
  ['tayara meubles sousse', 'meubles sousse'],
  ['vente éléctroménager tunisie', 'vente éléctroménager'],
  ['tayara sousse meuble', 'sousse meuble'],
  ['bureau occasion tayara', 'bureau'],
  ['tayara meuble nabeul', 'meuble nabeul'],
  ['tayara meubles tunis', 'meubles tunis'],
  ['tayara meuble occasion salon sousse', 'meuble salon sousse'],
  ['tayara tn meubles', 'meubles'],
  ['table de cuisine tayara', 'table de cuisine'],
  ['tayara meuble armoire', 'meuble armoire'],
  ['tayara tn meuble occasion ben arous', 'meuble ben arous'],
  ['tayara meubles cuisine', 'meubles cuisine'],
  ['électroménager tunisie en ligne', 'électroménager en ligne'],
  ['vente en ligne tunisie électroménager', 'électroménager'],
  ['meilleur prix électroménager', 'électroménager'],
  ['tunisie annonce tayara meuble occasion', 'tunisie annonce meuble'],
  ['tayara tn meuble nabeul', 'meuble nabeul'],
  ['tayara meuble occasion ennasr', 'meuble ennasr'],
  ['vente electromenager tunisie', 'vente electromenager'],
  ['armoire occasion tayara', 'armoire'],
  ['tayara meuble decoration', 'meuble decoration'],
  ['tayara sousse meubles occasion', 'sousse meubles'],
  ['meuble tayara tn', 'meuble'],
  ['tayara meuble chambre a coucher occasion', 'meuble chambre a coucher'],
  ['electromenager prix', 'electromenager'],
  ['élément de cuisine tayara', 'élément de cuisine'],
  ['chaise café occasion tunisie tayara', 'chaise café'],
  ['meuble tayara ben arous', 'meuble ben arous'],
  [
    'www tayara tn meubles occasion à vendre el menzah tunisie',
    'meubles à vendre el menzah',
  ],
  ['tayara salon occasion', 'salon'],
  ['vente chambre a coucher occasion tayara', 'vente chambre a coucher'],
  ['électroménager bosch tunisie prix', 'électroménager bosch'],
  ['vente meuble occasion tayara', 'vente meuble'],
  ['tayara meuble occasion salon', 'meuble salon'],
  ['chambre a coucher occasion tayara', 'chambre a coucher'],
  ['annonces tayara meubles', 'annonces meubles'],
  ['tayara meuble ariana', 'meuble ariana'],
  ['tayara mebel', 'mebel'],
  ['tayara tn meuble sousse', 'meuble sousse'],
  ['meuble bureau tunisie occasion tayara', 'meuble bureau'],
  ['meuble occasion a vendre sur tayara', 'meuble a vendre sur'],
  ['vente chambre à coucher occasion tayara', 'vente chambre à coucher'],
  [
    'prix tv iris electronique electroménager',
    'tv iris electronique & electroménager',
  ],
  ['tayara meuble bizerte', 'meuble bizerte'],
  ['meuble occasion tayara', 'meuble'],
  ['tayara tn meubles occasion', 'meubles'],
  ['tayara meuble occasion bizerte', 'meuble bizerte'],
  ['prix electromenager tunisie', 'electromenager'],
  [
    'petit électroménager déstockage electroménager',
    'petit électroménager déstockage electroménager',
  ],
  ['tayara meubles sfax', 'meubles sfax'],
  ['tayara meuble occasion monastir', 'meuble monastir'],
  ['tayara cuisine', 'cuisine'],
  ['tayara meuble occasion ben arous', 'meuble ben arous'],
  ['tayara meuble ben arous', 'meuble ben arous'],
  ['tayara meuble chambre à coucher occasion', 'meuble chambre à coucher'],
  ['tayara meubles occasion', 'meubles'],
  ['tayara tn meuble et decoration', 'meuble et decoration'],
  ['meuble occasion a vendre en tunisie tayara', 'meuble a vendre'],
  ['tayara garde robe occasion', 'garde robe'],
  ['tayara meublé chambre à coucher occasion', 'meublé chambre à coucher'],
  ['tayara vente meuble occasion', 'vente meuble'],
  ['tayara meuble', 'meuble'],
  ['salon occasion tunisie tayara', 'salon'],
  ['tayara chambre a coucher occasion', 'chambre a coucher'],
  ['achat electromenager en gros', 'achat electromenager en gros'],
  ['tayara meuble occasion a salon', 'meuble a salon'],
  ['tayara tn meuble tunisie', 'meuble'],
  ['jumia electromenager', 'jumia electromenager'],
  ['electroménager tunisie prix', 'electroménager'],
  ['salon occasion tayara', 'salon'],
  ['tayara meubles ariana', 'meubles ariana'],
  ['tayara nabeul meuble occasion', 'nabeul meuble'],
  ['tayara mobilier tunis', 'mobilier tunis'],
  ['tayara tn meuble ariana', 'meuble ariana'],
  ['tayara tn meuble tunis', 'meuble tunis'],
  ['meubles occasion tayara', 'meubles'],
  ['tayara sfax meuble', 'sfax meuble'],
  ['www tayara tn meuble occasion', 'meuble'],
  ['electromenager tunisie en ligne', 'electromenager en ligne'],
  ['tayara meubles salon', 'meubles salon'],
  ['tayara meuble et decoration', 'meuble et decoration'],
  ['achat en gros electromenager', 'achat en gros electromenager'],
  ['tayara meuble occasion nabeul', 'meuble nabeul'],
  ['meuble tayara', 'meuble'],
  ['meuble d occasion a vendre en tunisie tayara', 'meuble a vendre'],
  ['tayara meuble occasion a vendre', 'meuble a vendre'],
  ['tayara vente meuble', 'vente meuble'],
  ['tayara chambre à coucher occasion ariana', 'chambre à coucher ariana'],
  ['tayara meubles monastir', 'meubles monastir'],
  ['tayara meubel', 'meubel'],
  ['tayara salon occasion sfax', 'salon sfax'],
  ['tayara mobilier', 'mobilier'],
  ['tayara meubles manouba', 'meubles manouba'],
  ['tayara meuble occasion à salon', 'meuble à salon'],
  ['tayara tn salon occasion', 'salon'],
  [
    'trois rivières mauricie acheter et vendre appareils électroménagers',
    'trois rivières mauricie acheter et vendre appareils électroménagers',
  ],
  ['tayara monastir meuble', 'monastir meuble'],
  ['vente electromenager sfax', 'vente electromenager sfax'],
  ['devis electromenager en ligne', 'devis electromenager en ligne'],
]);
