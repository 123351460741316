import React, { useState } from 'react';

import Link from 'next/link';
import { HiArrowNarrowRight, HiChevronDown } from 'react-icons/hi';
import { useWindowSize } from 'usehooks-ts';

import Text from '@/tayara-kit/Text';

const SubcategoriesSection = () => {
  const windowSize = useWindowSize();
  const [villesIsExpanded, setVilleIsExpanded] = useState(false);
  const [neufIsExpanded, setNeufIsExpanded] = useState(false);

  const toggleVilleExpanded = () => {
    setVilleIsExpanded(!villesIsExpanded);
  };

  const toggleNeufExpanded = () => {
    setNeufIsExpanded(!neufIsExpanded);
  };

  const subCategoryItems = [
    {
      id: 1,
      label: 'Véhicules',
      link: '/ads/c/V%C3%A9hicules/',
      SubCategorySubItems: {
        links: [
          '/ads/c/Véhicules/Motos/?page=1',
          '/ads/c/Véhicules/Camions/?page=1',
          '/ads/c/Véhicules/Voitures/?page=1',
          '/ads/c/Véhicules/Pièces%20et%20Accessoires%20pour%20véhicules/?page=1',
        ],
        images: [
          '/media/moto.png',
          '/media/camions.png',
          '/media/voiture.png',
          '/media/pieces.png',
        ],
        labels: ['Motos', 'Camions', 'Voitures', 'Piéces de rechange'],
      },
    },
    {
      id: 2,
      label: 'Immobilier',
      link: '/ads/c/Immobilier/?page=1',
      SubCategorySubItems: {
        links: [
          '/ads/c/Immobilier/Maisons%20et%20Villas/?page=1',
          '/ads/c/Immobilier/Colocations/?page=1',
          '/ads/c/Immobilier/Magasins%2C%20Commerces%20et%20Locaux%20industriels/?page=1',
          '/ads/c/Immobilier/Locations%20de%20vacances/?page=1',
        ],
        images: [
          '/media/MaisonetVilla.png',
          '/media/Colocations.png',
          '/media/Magasins.png',
          '/media/Locationsdevacance.png',
        ],
        labels: [
          'Maison et Villa',
          'Colocations',
          'Magasins',
          'Location de vacances',
        ],
      },
    },
    {
      id: 3,
      label: 'Informatique et Multimedias',
      link: '/ads/c/Informatique%20et%20Multimedia/',
      SubCategorySubItems: {
        links: [
          '/ads/c/Informatique%20et%20Multimedias/Appareils%20photo%20et%20Caméras/?page=1',
          '/ads/c/Informatique%20et%20Multimedias/Ordinateurs%20portables/?page=1',
          '/ads/c/Informatique%20et%20Multimedias/Télévisions/?page=1',
          '/ads/c/Informatique%20et%20Multimedias/Téléphones/?page=1',
        ],
        images: [
          '/media/AppareilsPhoto.png',
          '/media/OrdinateursPortables.png',
          '/media/Télévisions.png',
          '/media/Téléphones.png',
        ],
        labels: [
          'Appareils Photos',
          'Ordinateurs Portables',
          'Télévisions',
          'Téléphones',
        ],
      },
    },
    {
      id: 4,
      label: 'Habillement et bien-être',
      link: '/ads/c/Habillement%20et%20Bien%20Etre/',
      SubCategorySubItems: {
        links: [
          '/ads/c/Habillement%20et%20Bien%20Etre/Equipements%20pour%20enfant%20et%20bébé/?page=1',
          '/ads/c/Habillement%20et%20Bien%20Etre/Vêtements/?page=1',
          '/ads/c/Habillement%20et%20Bien%20Etre/Montres%20et%20Bijoux/?page=1',
          '/ads/c/Habillement%20et%20Bien%20Etre/Produits%20de%20beauté/?page=1',
        ],
        images: [
          '/media/Equipementspourenfant.png',
          '/media/Vetements.png',
          '/media/Bijoux.png',
          '/media/Produitsdebeautés.png',
        ],
        labels: [
          'Équipement pour enfants',
          'Vêtements',
          'Bijoux',
          'Produits de beauté',
        ],
      },
    },
  ];

  const villePopulairesItems = [
    {
      id: 1,
      label: 'Tunis',
      villePopulairesSubItems: {
        labels: [
          "Voitures d'occasion à Tunis",
          'Immobilier à Tunis',
          'Informatique et Multimédia à Tunis',
          'Maison et jardin à Tunis',
        ],
        links: [
          'https://www.tayara.tn/ads/c/V%C3%A9hicules/l/Tunis/?page=1',
          'https://www.tayara.tn/ads/c/Immobilier/l/Tunis/?page=1',
          'https://www.tayara.tn/ads/c/Informatique%20et%20Multimedias/l/Tunis/?page=1',
          'https://www.tayara.tn/ads/c/Pour%20la%20Maison%20et%20Jardin/l/Tunis/?page=1',
        ],
      },
    },
    {
      id: 2,
      label: 'Sousse',
      villePopulairesSubItems: {
        labels: [
          "Voitures d'occasion à Sousse",
          'Immobilier à Sousse',
          'Informatique et Multimédia à Sousse',
          'Maison et jardin à Sousse',
        ],
        links: [
          'https://www.tayara.tn/ads/c/V%C3%A9hicules/l/Sousse/?page=1',
          'https://www.tayara.tn/ads/c/Immobilier/l/Sousse/?page=1',
          'https://www.tayara.tn/ads/c/Informatique%20et%20Multimedias/l/Sousse/?page=1',
          'https://www.tayara.tn/ads/c/Pour%20la%20Maison%20et%20Jardin/l/Sousse/?page=1',
        ],
      },
    },
    {
      id: 3,
      label: 'Monastir',
      villePopulairesSubItems: {
        labels: [
          "Voitures d'occasion à Monastir",
          'Immobilier à Monastir',
          'Informatique et Multimédia à Monastir',
          'Maison et jardin à Monastir',
        ],
        links: [
          'https://www.tayara.tn/ads/c/V%C3%A9hicules/Voitures/l/Monastir/?page=1',
          'https://www.tayara.tn/ads/c/Immobilier/l/Monastir/?page=1',
          'https://www.tayara.tn/ads/c/Informatique%20et%20Multimedias/l/Monastir/?page=1',
          'https://www.tayara.tn/ads/c/Pour%20la%20Maison%20et%20Jardin/l/Monastir/?page=1',
        ],
      },
    },
    {
      id: 4,
      label: 'Sfax',
      villePopulairesSubItems: {
        labels: [
          "Voitures d'occasion à Sfax",
          'Immobilier à Sfax',
          'Informatique et Multimédia à Sfax',
          'Maison et jardin à Sfax',
        ],
        links: [
          'https://www.tayara.tn/ads/c/V%C3%A9hicules/Voitures/l/Sfax/',
          'https://www.tayara.tn/ads/c/Immobilier/l/Sfax/',
          'https://www.tayara.tn/ads/c/Informatique%20et%20Multimedias/l/Sfax/?page=1',
          'https://www.tayara.tn/ads/c/Pour%20la%20Maison%20et%20Jardin/l/Sfax/?page=1',
        ],
      },
    },
    {
      id: 5,
      label: 'Bizerte',
      villePopulairesSubItems: {
        labels: [
          "Voitures d'occasion à Bizerte",
          'Immobilier à Bizerte',
          'Informatique et Multimédia à Bizerte',
          'Maison et jardin à Bizerte',
        ],
        links: [
          'https://www.tayara.tn/ads/c/V%C3%A9hicules/Voitures/l/Bizerte/?page=1',
          'https://www.tayara.tn/ads/c/Immobilier/l/Bizerte/?page=1',
          'https://www.tayara.tn/ads/c/Informatique%20et%20Multimedias/l/Bizerte/?page=1',
          'https://www.tayara.tn/ads/c/Pour%20la%20Maison%20et%20Jardin/l/Bizerte/?page=1',
        ],
      },
    },
    {
      id: 6,
      label: 'Ariana',
      villePopulairesSubItems: {
        labels: [
          "Voitures d'occasion à Ariana",
          'Immobilier à Ariana',
          'Informatique et Multimédia à Ariana',
          'Maison et jardin à Ariana',
        ],
        links: [
          'https://www.tayara.tn/ads/c/V%C3%A9hicules/Voitures/l/Ariana/?page=1',
          'https://www.tayara.tn/ads/c/Immobilier/l/Ariana/?page=1',
          'https://www.tayara.tn/ads/c/Informatique%20et%20Multimedias/l/Ariana/?page=1',
          'https://www.tayara.tn/ads/c/Pour%20la%20Maison%20et%20Jardin/l/Ariana/?page=1',
        ],
      },
    },
    {
      id: 7,
      label: 'Nabeul',
      villePopulairesSubItems: {
        labels: [
          "Voitures d'occasion à Nabeul",
          'Immobilier à Nabeul',
          'Informatique et Multimédia à Nabeul',
          'Maison et jardin à Nabeul',
        ],
        links: [
          'https://www.tayara.tn/ads/c/V%C3%A9hicules/Voitures/l/Nabeul/?page=1',
          'https://www.tayara.tn/ads/c/Immobilier/l/Nabeul/?page=1',
          'https://www.tayara.tn/ads/c/Informatique%20et%20Multimedias/l/Nabeul/?page=1',
          'https://www.tayara.tn/ads/c/Pour%20la%20Maison%20et%20Jardin/l/Nabeul/?page=1',
        ],
      },
    },
    {
      id: 8,
      label: 'Ben Arous',
      villePopulairesSubItems: {
        labels: [
          "Voitures d'occasion à Ben Arous",
          'Immobilier à Ben Arous',
          'Informatique et Multimédia à Ben Arous',
          'Maison et jardin à Ben Arous',
        ],
        links: [
          'https://www.tayara.tn/ads/c/V%C3%A9hicules/Voitures/l/Ben%20Arous/?page=1',
          'https://www.tayara.tn/ads/c/Immobilier/l/Ben%20Arous/?page=1',
          'https://www.tayara.tn/ads/c/Informatique%20et%20Multimedias/l/Ben%20Arous/?page=1',
          'https://www.tayara.tn/ads/c/Pour%20la%20Maison%20et%20Jardin/l/Ben%20Arous/?page=1',
        ],
      },
    },
    {
      id: 9,
      label: 'Hammamet',
      villePopulairesSubItems: {
        labels: [
          "Voitures d'occasion à Hammamet",
          'Immobilier à Hammamet',
          'Informatique et Multimédia à Hammamet',
          'Maison et jardin à Hammamet',
        ],
        links: [
          'https://www.tayara.tn/ads/c/Pour%20la%20Maison%20et%20Jardin/l/Nabeul/Hammamet%2CHammamet%20Centre%2CHammamet%20Nord/?page=1',
          'https://www.tayara.tn/ads/c/Immobilier/l/Nabeul/Hammamet%2CHammamet%20Centre%2CHammamet%20Nord/?page=1',
          'https://www.tayara.tn/ads/c/Informatique%20et%20Multimedias/l/Nabeul/Hammamet%2CHammamet%20Centre%2CHammamet%20Nord/?page=1',
          'https://www.tayara.tn/ads/c/Pour%20la%20Maison%20et%20Jardin/l/Nabeul/Hammamet%2CHammamet%20Centre%2CHammamet%20Nord/?page=1',
        ],
      },
    },
  ];

  const produitsNeufsItems = [
    {
      id: 1,
      villePopulairesSubItems: {
        labels: [
          'Motos neuves',
          'Voitures neuves',
          'Biens immobiliers neufs',
          'Appareils photos neufs',
          'Ordinateurs portables neufs',
          'Télévisions neuves',
          'Téléphones neufs',
          'Équipements pour enfants neufs',
          'Vêtements neufs',
          'Bijoux neufs',
          'Produits de beauté',
        ],
        links: [
          '/ads/c/Véhicules/Motos/t/Neuf/?page=1',
          '/ads/c/Véhicules/Voitures/t/Neuf/?page=1',
          '/ads/c/Immobilier/Maisons%20et%20Villas/t/Neuf/?page=1',
          '/ads/c/Informatique%20et%20Multimedias/Appareils%20photo%20et%20Caméras/t/Neuf/?page=1',
          '/ads/c/Informatique%20et%20Multimedias/Ordinateurs%20portables/t/Neuf/?page=1',
          '/ads/c/Informatique%20et%20Multimedias/Télévisions/t/Neuf/?page=1',
          '/ads/c/Informatique%20et%20Multimedias/Téléphones/t/Neuf/?page=1',
          '/ads/c/Habillement%20et%20Bien%20Etre/Equipements%20pour%20enfant%20et%20bébé/t/Neuf/?page=1',
          '/ads/c/Habillement%20et%20Bien%20Etre/Vêtements/t/Neuf/?page=1',
          '/ads/c/Habillement%20et%20Bien%20Etre/Montres%20et%20Bijoux/t/Neuf/?page=1',
          '/ads/c/Habillement%20et%20Bien%20Etre/Produits%20de%20beauté/t/Neuf/?page=1',
        ],
      },
    },
  ];

  const firstGroup = subCategoryItems.filter(
    (item) => item.id === 1 || item.id === 2
  );
  const secondGroup = subCategoryItems.filter(
    (item) => item.id === 3 || item.id === 4
  );

  return (
    <>
      <div className="w-full flex flex-col mt-10">
        <div className="w-full gap-x-28 flex flex-wrap justify-center items-center md:justify-between mb-10 lg:mt-0 gap-y-4">
          <h2 className=" text-lg md:text-xl font-extrabold lg:truncate text-center">
            Découvrez notre univers
          </h2>
          <Link
            href={`/search`}
            passHref
            className="flex items-center gap-3 text-sm lg:text-base font-bold pl-3 md:ml-10 lg:mx-0 truncate text-neutral-800 underline"
          >
            Afficher plus <HiArrowNarrowRight size={20} />
          </Link>
        </div>
        <div
          className={`w-full flex justify-center gap-4 ${
            windowSize.width < 1575 ? 'flex-wrap' : 'flex-nowrap'
          }`}
        >
          <div className="w-full flex flex-wrap lg:flex-nowrap gap-4 grow">
            {firstGroup.map((subCategoryItem) => (
              <div
                key={subCategoryItem.id}
                className="flex flex-col gap-4 p-4 md:p-10 rounded-xl border-[1px] border-neutral-200 w-full grow bg-white"
              >
                <h2 className="font-bold text-lg">{subCategoryItem.label}</h2>
                <div className="grid grid-rows-2 grid-flow-col gap-4 grow">
                  {subCategoryItem.SubCategorySubItems.links.map(
                    (link, index) => (
                      <Link
                        key={index}
                        href={link}
                        passHref
                        className="flex justify-center"
                      >
                        <div className="relative min-w-[120px] w-full h-[120px] lg:h-[120px]  overflow-hidden transition-transform inline-block rounded-xl">
                          <div
                            className="hover:scale-110 duration-500 ease-in-out cursor-pointer relative min-w-[120px] w-full h-[120px] lg:h-[120px]  overflow-hidden transition-transform inline-block rounded-xl bg-center bg-cover grow"
                            style={{
                              backgroundImage: `url(${subCategoryItem.SubCategorySubItems.images[index]})`,
                            }}
                          ></div>
                          <span className="absolute bottom-2 left-4 text-base text-white font-bold leading-4 p-2">
                            {subCategoryItem.SubCategorySubItems.labels[index]}
                          </span>
                        </div>
                      </Link>
                    )
                  )}
                </div>
                <Link
                  href={subCategoryItem.link}
                  passHref
                  className="flex items-center justify-end gap-3 text-sm lg:text-base font-bold truncate text-neutral-800 underline"
                >
                  Voir plus <HiArrowNarrowRight size={16} className="mt-1" />
                </Link>
              </div>
            ))}
          </div>
          <div className="w-full flex flex-wrap lg:flex-nowrap gap-4 grow">
            {secondGroup.map((subCategoryItem) => (
              <div
                key={subCategoryItem.id}
                className="flex flex-col gap-4 p-4 md:p-10 rounded-xl border-[1px] border-neutral-200 w-full grow bg-white"
              >
                <h2 className="font-bold text-lg">{subCategoryItem.label}</h2>
                <div className="grid grid-rows-2 grid-flow-col gap-4 grow">
                  {subCategoryItem.SubCategorySubItems.links.map(
                    (link, index) => (
                      <Link
                        key={index}
                        href={link}
                        passHref
                        className="flex justify-center"
                      >
                        <div className="relative min-w-[120px] w-full h-[120px] lg:h-[120px]  overflow-hidden transition-transform inline-block rounded-xl">
                          <div
                            className="hover:scale-110 duration-500 ease-in-out cursor-pointer relative min-w-[120px] w-full h-[120px] lg:h-[120px]  overflow-hidden transition-transform inline-block rounded-xl bg-center bg-cover grow"
                            style={{
                              backgroundImage: `url(${subCategoryItem.SubCategorySubItems.images[index]})`,
                            }}
                          ></div>
                          <span className="absolute bottom-2 left-4 text-base text-white font-bold leading-4 p-2">
                            {subCategoryItem.SubCategorySubItems.labels[index]}
                          </span>
                        </div>
                      </Link>
                    )
                  )}
                </div>
                <Link
                  href={subCategoryItem.link}
                  passHref
                  className="flex items-center justify-end gap-3 text-sm lg:text-base font-bold truncate text-neutral-800 underline"
                >
                  Voir plus <HiArrowNarrowRight size={16} className="mt-1" />
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div
        className="w-full rounded-xl border-[1px] border-neutral-200 p-6 lg:p-8 mt-5 bg-white cursor-pointer"
        onClick={() => toggleVilleExpanded()}
      >
        <div className="w-full flex justify-between">
          <h2 className="text-sm md:text-lg font-extrabold uppercase truncate">
            Villes populaires
          </h2>
          <button className="flex justify-center items-center gap-x-1 text-primary font-bold">
            <Text
              as="span"
              text={`${!villesIsExpanded ? 'Voir plus' : 'Moins'}`}
              className="truncate"
            />
            <HiChevronDown
              size={18}
              className={`${villesIsExpanded ? 'rotate-180' : ''} mt-1`}
            />
          </button>
        </div>

        {villesIsExpanded && (
          <div className="w-full flex flex-wrap lg:flex-nowrap mt-10 gap-8">
            <div
              className="w-full min-w-1/3 lg:w-1/3 h-[200px]  lg:h-[360px] bg-center bg-contain bg-no-repeat"
              style={{
                backgroundImage: `url(/media/MapOfTunisia.png)`,
              }}
            ></div>

            <div className="w-full flex  lg:mx-28 mb-4">
              <div className="w-full h-fit flex flex-wrap justify-center lg:justify-start gap-x-20 gap-y-10 ">
                {villePopulairesItems.map((villePopulairesItem) => (
                  <div
                    key={villePopulairesItem.id}
                    className="w-fit h-fit flex flex-col gap-y-4"
                  >
                    <h2 className="text-lg font-bold">
                      {villePopulairesItem.label}
                    </h2>
                    <div className="w-fit h-fit flex flex-col gap-1">
                      {villePopulairesItem.villePopulairesSubItems.links.map(
                        (link, index) => (
                          <Link
                            key={index}
                            href={link}
                            passHref
                            className="flex justify-start"
                          >
                            <span className="text-start text-sm text-neutral-800 font-bold underline">
                              {
                                villePopulairesItem.villePopulairesSubItems
                                  .labels[index]
                              }
                            </span>
                          </Link>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className="w-full rounded-xl border-[1px] border-neutral-200 p-6 lg:p-8 mt-5 bg-white cursor-pointer"
        onClick={() => toggleNeufExpanded()}
      >
        <div className="w-full flex justify-between">
          <h2 className="text-sm md:text-lg font-extrabold uppercase truncate">
            Produits neufs
          </h2>
          <button className="flex justify-center items-center gap-x-1 text-primary font-bold">
            <Text
              as="span"
              text={`${!neufIsExpanded ? 'Voir plus' : 'Moins'}`}
              className="truncate"
            />
            <HiChevronDown
              size={18}
              className={`${neufIsExpanded ? 'rotate-180' : ''} mt-1`}
            />
          </button>
        </div>

        {neufIsExpanded && (
          <div className="w-full flex flex-wrap lg:flex-nowrap mt-10 gap-8">
            <div className="w-full flex">
              <div className="w-full h-fit flex flex-wrap justify-center lg:justify-start gap-x-20 gap-y-10 ">
                {produitsNeufsItems.map((produitsNeufsItem) => (
                  <div
                    className="w-fit h-fit flex flex-wrap justify-center md:justify-start gap-4"
                    key={produitsNeufsItem.id}
                  >
                    {produitsNeufsItem.villePopulairesSubItems.links.map(
                      (link, index) => (
                        <Link
                          key={index}
                          href={link}
                          passHref
                          className="flex justify-start px-4 py-2 rounded-3xl bg-neutral-100 hover:bg-neutral-200 cursor-pointer"
                        >
                          <span className="text-center text-sm text-neutral-800 font-bold truncate">
                            {
                              produitsNeufsItem.villePopulairesSubItems.labels[
                                index
                              ]
                            }
                          </span>
                        </Link>
                      )
                    )}
                    <Link
                      href={'/search/?productType=Neuf&page=1'}
                      passHref
                      className="flex justify-start px-4 py-2  cursor-pointer"
                    >
                      <span className="inline-flex gap-2 text-center text-sm text-primary underline font-bold truncate">
                        Découvrez tous les produits neufs{' '}
                        <HiArrowNarrowRight size={16} className="mt-1" />
                      </span>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SubcategoriesSection;
