import React, { useRef, useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { useWindowSize } from 'usehooks-ts';

const items = [
  {
    id: 1,
    link: '/ads/c/Véhicules/?page=1',
    DesktopImage: '/media/cars-carousel-desktop.png',
    mobileImage: '/media/cars-carousel-mobile.png',
    label: 'Véhicules',
    circle:
      'bg-[#FDC305]/50 hover:translate-x-3 hover:translate-y-3 top-10 -left-4',
  },
  {
    id: 2,
    link: '/ads/c/Immobilier/?page=1',
    DesktopImage: '/media/realestate-carousel-desktop.png',
    mobileImage: '/media/realestate-carousel-mobile.png',
    label: 'Immobilier',
    circle:
      'bg-[#39B889]/50 hover:translate-x-3 hover:translate-y-0 top-6 left-4',
  },
  {
    id: 3,
    link: '/ads/c/ImmoNeuf/Immobilier%20Neuf/?page=1',
    DesktopImage: '/media/key-desktop.png',
    mobileImage: '/media/key.png',
    label: 'ImmoNeuf',
    circle:
      'bg-[#065797]/50 hover:translate-x-3 hover:translate-y-0 top-6 left-4',
  },
  {
    id: 4,
    link: '/ads/c/Informatique%20et%20Multimedias/?page=1',
    DesktopImage: '/media/multimedia-carousel-desktop.png',
    mobileImage: '/media/multimedia-carousel-mobile.png',
    label: 'Informatique et Multimedia',
    circle:
      'bg-[#39DEEE]/50 hover:translate-x-3 hover:translate-y-3 top-6 left-10 lg:left-0',
  },
  {
    id: 5,
    link: '/ads/c/Pour%20la%20Maison%20et%20Jardin/?page=1',
    DesktopImage: '/media/home-carousel-desktop.png',
    mobileImage: '/media/home-carousel-mobile.png',
    label: 'Maison et Jardin',
    circle: 'bg-[#CF4032]/50 hover:translate-x-0 hover:translate-y-3 top-5',
  },
  {
    id: 6,
    link: '/ads/c/Loisirs%20et%20Divertissement/?page=1',
    DesktopImage: '/media/loisir-carousel-desktop.png',
    mobileImage: '/media/loisir-carousel-mobile.png',
    label: 'Loisirs et Divertissement',
    circle:
      'bg-[#FADBD3]/50 hover:-translate-x-3 hover:translate-y-3 top-6 right-3',
  },
  {
    id: 7,
    link: '/ads/c/Habillement%20et%20Bien%20Etre/?page=1',
    DesktopImage: '/media/clothes-carousel-desktop.png',
    mobileImage: '/media/clothes-carousel-mobile.png',
    label: 'Habillement et Bien Etre',
    circle:
      'bg-[#404EBB]/50 hover:translate-x-0 hover:translate-y-3 top-6 left-4',
  },
  {
    id: 8,
    link: '/ads/c/Emploi%20et%20Services/?page=1',
    DesktopImage: '/media/jobs-carousel-desktop.png',
    mobileImage: '/media/jobs-carousel-mobile.png',
    label: 'Emploi et Services',
    circle:
      'bg-[#979595]/50 hover:translate-x-3 hover:translate-y-3 top-10 left-0',
  },
  {
    id: 9,
    link: '/ads/c/Entreprises/?page=1',
    DesktopImage: '/media/entreprise-carousel-desktop.png',
    mobileImage: '/media/entreprise-carousel-mobile.png',
    label: 'Entreprises',
    circle:
      'bg-[#F48D83]/50 hover:translate-x-0 hover:translate-y-3 top-2 left-1',
  },
];

const CategoriesCarousel = () => {
  const carouselRef: any = useRef(null);
  const [showPrevButton, setShowPrevButton] = useState(false);
  const [showNextButton, setShowNextButton] = useState(true);
  const windowSize = useWindowSize();

  const handlePrevClick = () => {
    carouselRef.current.scrollBy({ left: -200, behavior: 'smooth' });
    // setShowNextButton(true); // Show next button when clicking prev
  };

  const handleNextClick = () => {
    carouselRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  };

  const handleScroll = () => {
    const { scrollLeft } = carouselRef.current;
    const { scrollWidth } = carouselRef.current;
    const { clientWidth } = carouselRef.current;
    const isEnd = scrollLeft + clientWidth >= scrollWidth;

    setShowPrevButton(scrollLeft > 0); // Show prev button if scrolled past the initial item

    if (isEnd) {
      setShowNextButton(false); // Hide next button when reaching the end
    } else {
      setShowNextButton(true); // Show next button otherwise
    }
  };
  return (
    <div className="w-full flex flex-col md:mt-5">
      <h2 className=" text-lg md:text-xl font-extrabold mt-5 lg:mt-0">
        Explorez toutes les catégories
      </h2>

      <div className="w-full justify-center items-center overflow-hidden relative">
        <div
          ref={carouselRef}
          className="w-full flex justify-between items-center pl-2 md:pl-0 gap-x-2 md:gap-x-5 overflow-x-scroll md:overflow-hidden"
          onScroll={handleScroll}
        >
          {items.map((item, index) => (
            <div
              key={index}
              className={`flex flex-col m-1 items-center justify-between w-max lg:w-40 h-32 relative`}
            >
              <Link
                href={item.link}
                replace
                passHref
                className="flex mx-auto justify-center items-end w-max lg:w-40 h-20"
              >
                <div
                  className={`absolute w-10 h-10 md:w-14 md:h-14 rounded-full ${item.circle} hover:transition ease-in  duration-300 cursor-pointer transition-transform duration-600`}
                ></div>

                <Image
                  loading="lazy"
                  width={0}
                  height={0}
                  className="w-auto h-auto transition-all duration-300"
                  src={
                    windowSize.width > 480
                      ? item.DesktopImage
                      : item.mobileImage
                  }
                  unoptimized
                  alt="catégorie tayara"
                />
              </Link>
              <Link href={item.link} replace passHref>
                <span className="text-center truncate text-sm md:text-base font-extrabold text-neutral-800">
                  {item.label}
                </span>
              </Link>
            </div>
          ))}
        </div>
        {windowSize.width < 1900 && (
          <>
            <div className="absolute top-0 right-0 px-8 md:px-10 h-[160px] bg-gradient-to-l from-neutral-50 z-40">
              <button
                style={{ display: showNextButton ? 'block' : 'none' }}
                className="absolute top-1/2 transform -translate-y-1/2 right-0 bg-white border border-neutral-400 p-3 md:p-4 rounded-full text-neutral-700 hover:text-neutral-900 focus:outline-none focus:bg-neutral-100 z-50"
                onClick={handleNextClick}
              >
                <MdArrowForwardIos size={20} />
              </button>
            </div>
            <div className="absolute top-0 left-0 px-2 md:px-0 h-[160px] bg-gradient-to-r from-neutral-50 z-40">
              <button
                style={{ display: showPrevButton ? 'block' : 'none' }}
                className="absolute top-1/2 transform -translate-y-1/2 left-0 bg-white border border-neutral-400 p-3 md:p-4 rounded-full text-neutral-700 hover:text-neutral-900 focus:outline-none focus:bg-neutral-100 z-50"
                onClick={handlePrevClick}
              >
                <MdArrowBackIosNew size={20} />
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CategoriesCarousel;
