import { useWindowSize } from 'usehooks-ts';

export enum Breakpoints {
  xs,
  sm,
  md,
  lg,
  xl,
  '2xl',
}

const useBreakpoints = (
  // targetBreakpoint?: Breakpoints,
  obj?: { [key: string]: number }
) => {
  const breakpoints = [
    0, // xs
    640, // sm
    768, // md
    1010, // lg
    1200, // xl
    1428, // 2xl
  ];
  const windowSize = useWindowSize();

  if (obj) {
    const k = breakpoints;
    k.push(windowSize.width);
    k.sort((a, b) => a - b);
    const currentBpSize = k.indexOf(windowSize.width) - 1;
    const currentBp = Breakpoints[currentBpSize]!;
    if (obj[currentBp]) {
      return obj[currentBp];
    }
  }
  return undefined;

  // if (targetBreakpoint) {
  //   if (
  //     windowSize.width > breakpoints[targetBreakpoint]! &&
  //     breakpoints[targetBreakpoint + 1] &&
  //     windowSize.width < breakpoints[targetBreakpoint + 1]!
  //   ) {
  //     return true;
  //   }
  //   if (
  //     !breakpoints[targetBreakpoint + 1]! &&
  //     windowSize.width > breakpoints[targetBreakpoint]!
  //   ) {
  //     return true;
  //   }
  //   return false;
  // }
  // return undefined;
};

export default useBreakpoints;
