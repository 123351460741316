/* eslint-disable @typescript-eslint/naming-convention */

import React, { useState } from 'react';

import { useAtom } from 'jotai';
import { isArray, isEmpty } from 'lodash-es';
import { NextPageContext } from 'next';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { HiArrowNarrowRight, HiX } from 'react-icons/hi';
import { useBoolean, useEffectOnce, useWindowSize } from 'usehooks-ts';

import {
  FilterRequestObject,
  SearchRequestObject,
} from '@/api/elasticsearch/models';
import { getSSRInitialListings } from '@/api/elasticsearch/server';
import { searchElasticApi } from '@/api/fetch/rest-search-api';
import { rudderstackTrackEvent } from '@/api/rudderstack/client';
import { APP_CONFIG } from '@/app-config';
import { LoadHeavyContentAtom, pageLoading } from '@/lib/store';
import { cleanObject } from '@/lib/utils';
import ImmoxSection from '@/modules/auth/components/ImmoxSection';
import SubcategoriesSection from '@/modules/auth/components/SubcategoriesSection';
import TayaraProductsSection from '@/modules/auth/components/TayaraProductsSection';
import useStorage from '@/modules/auth/hooks/useStorage';
import GoldenClassified from '@/modules/core/components/FeaturedClassifiedsCarousel/GoldenClassified';
import Layout from '@/modules/core/components/Layout';
import ShowcasedShopOfWeekCarousel, {
  ShopOfMonthCarouselMetadata,
} from '@/modules/core/components/ShowcasedCarousel/ShowcasedShopOfMonthCarousel';
import { useElastiSearchAdList } from '@/modules/core/hooks/useElasticSearchServer';
import { SearchedListingsAction } from '@/modules/core/store/store';
import { IListingItem } from '@/modules/core/types';
import { Meta } from '@/seo/Meta';
import CategoriesCarousel from '@/tayara-kit/CategoriesCarousel';
import { NEXT_API_URL } from 'environment';

import { NextPageWithLayout } from './_app';

export interface Props {
  searchedListingsAction: SearchedListingsAction | undefined;
  goldenListing: IListingItem | null;
  vechiculeHits: IListingItem[] | null;
  ImmoBilierHits: IListingItem[] | null;
  InfoHits: IListingItem[] | null;
  ssrSearchedListingsAction?: SearchedListingsAction;
  ssrGoldenListing?: IListingItem | null;
  [key: string]: any;
}

interface LinkData {
  text: string;
  href: string;
}

const linkData: LinkData[] = [
  { text: 'voitures', href: '/ads/c/Véhicules/?page=1' },
  { text: 'immobilier', href: '/ads/c/Immobilier/?page=1' },
  {
    text: 'vêtements',
    href: '/ads/c/Habillement%20et%20Bien%20Etre/?page=1',
  },
  {
    text: 'accessoires',
    href: '/ads/c/Habillement%20et%20Bien%20Etre/Sacs%20et%20Accessoires/?page=1',
  },
  {
    text: 'smartphones',
    href: '/ads/c/Informatique%20et%20Multimedias/Téléphones/?page=1',
  },
  {
    text: 'appareils électroniques',
    href: '/ads/c/Informatique%20et%20Multimedias/Accessoires%20informatiques%20et%20Gadgets/?page=1',
  },
  {
    text: 'équipements de maison',
    href: '/ads/c/Pour%20la%20Maison%20et%20Jardin/Electroménagers%20et%20Vaisselles/?page=1',
  },
];

const DescriptionLink: React.FC<{ href: string }> = ({ href, children }) => (
  <Link className="text-black underline font-semibold" href={href}>
    {children}
  </Link>
);

const Index: NextPageWithLayout<Props> = (
  { ssrGoldenListing, ssrSearchedListingsAction },
  props: Props
) => {
  const [loadHeavyContent] = useAtom(LoadHeavyContentAtom);
  const [isLoading] = useAtom(pageLoading);
  // const [stickyBar] = useAtom(stickyBannerAtom);
  const windowSize = useWindowSize();

  const HorizontalBannerAd = dynamic(
    async () => import('@/tayara-kit/banners/HorizontalBannerAd'),
    {
      ssr: false,
    }
  );

  const [shopOfMonthId, setShopOfMonthId] = useState<string[]>([]);
  const [shopOfWeekId, setShopOfWeekId] = useState<string>('');

  useEffectOnce(() => {
    fetch(`${NEXT_API_URL}/settings`)
      .then((res) => res.json())
      .then((settings) => {
        if (settings?.shopOfMonthId) {
          setShopOfMonthId(settings.shopOfMonthId);
        }
        if (typeof settings?.shopOfWeekId === 'string') {
          setShopOfWeekId(settings.shopOfWeekId);
        }
      });
    rudderstackTrackEvent('Visited Home Screen');
    rudderstackTrackEvent('page_view', { page_type: 'home' });
  });

  const FEATUREDSHOPOFWEEK: ShopOfMonthCarouselMetadata[] = [];
  if (isArray(shopOfMonthId) && shopOfMonthId?.length > 0) {
    shopOfMonthId?.forEach((shop) => {
      FEATUREDSHOPOFWEEK.push({
        shopOfMonthId: shop,
        shopOfWeekId,
        title: 'Explorez la boutique de la semaine',
        posterGradient: 'from-blue-600 via-blue-800 to-blue-900',
        posterShadow: 'shadow-blue-400',
        posterText: 'Boutique de la semaine',
        shopLabel: 'Shop du mois',
        shopLink: `shop/${shop}`,
        posterImage: '/media/storeMarket.png',
        posterDescription:
          'Voitures, motos, remorques et caravanes, engins agricoles et BTP, pièces et accessoires, camions, bateaux...',
        ssrTag: '',
      });
    });
  }

  // const FEATUREDSECONDSHOPOFWEEK: ShopOfMonthCarouselMetadata[] = [
  //   {
  //     shopOfWeekId,
  //     shopOfMonthId,
  //     title: 'Explorez lagence de la semaine ',
  //     posterGradient: 'from-blue-600 via-blue-800 to-blue-900',
  //     posterShadow: 'shadow-blue-400',
  //     posterText: 'Boutique de la semaine',
  //     shopLabel: 'Shop du mois',
  //     shopLink: `shop/${shopOfWeekId}`,
  //     posterImage: '/media/storeMarket.png',
  //     posterDescription:
  //       'Voitures, motos, remorques et caravanes, engins agricoles et BTP, pièces et accessoires, camions, bateaux...',
  //     ssrTag: '',
  //   },
  // ];

  const { goldenListing } = useElastiSearchAdList(
    ssrSearchedListingsAction,
    ssrGoldenListing
  );

  const { getItem, setItem } = useStorage();
  if (!getItem('SessionID')) {
    setItem(
      'SessionID',
      `_${Math.random().toString(36).substr(2, 9)}`,
      'session'
    );
  }

  const { value: googleAdClosed, setTrue: closeGoogleAd } = useBoolean(false);

  return (
    <>
      <Meta
        title={APP_CONFIG.title}
        description={APP_CONFIG.description}
        canonical={APP_CONFIG.basePath}
      />

      <div
        className={`px-2 md:px-12 lg:px-14 
         `}
      >
        <>
          <div
            className="hidden md:flex w-full flex-col justify-center items-center border-[1px] bg-white border-neutral-200 rounded-2xl h-fit mt-5 bg-no-repeat bg-cover"
            style={{
              backgroundImage: `url(/media/slogoncover.png)`,
            }}
          >
            <div className="w-full flex items-center justify-between">
              <div>
                {windowSize.width > 1300 && (
                  <>
                    <img
                      className="top-0 -left-0"
                      src="/media/leftbgslogon.png"
                      alt=""
                    />
                  </>
                )}
              </div>
              <div className="w-fit flex flex-col items-center mx-10">
                <span className="text-center text-lg md:text-xl font-extrabold text-neutral-800 truncate">
                  Vendez, achetez, prés de chez vous!
                </span>
                <span className="text-center text-sm md:text-sm font-semibold text-neutral-800 truncate">
                  Trouvez la bonne affaire parmi 123 979 annonces en Tunisie
                </span>
              </div>
              <div>
                {windowSize.width > 1300 && (
                  <>
                    <img
                      className="top-0 -right-0"
                      src="/media/rightbgslogon.png"
                      alt=""
                    />
                  </>
                )}
              </div>
            </div>
          </div>

          {!googleAdClosed ? (
            <div
              className="w-full h-[130px] md:h-[300px] flex justify-center items-center mx-auto bg-no-repeat bg-contain bg-local bg-center md:mt-5"
              style={{
                backgroundImage: `url(/media/pubcover.png)`,
              }}
            >
              <>
                <div className="relative h-[100px] md:h-[300px] w-[320px] md:w-full md:aspect-1000/300 md:max-w-[1000px] mx-auto mb-1 border border-neutral-200 bg-neutral-50/50 rounded-2xl overflow-hidden">
                  <button
                    tabIndex={-1}
                    className="p-1 rounded-full md:bg-neutral-100 text-neutral-400 absolute top-0 right-0 md:mr-4 md:mt-4 md:border border-neutral-300 "
                    onClick={closeGoogleAd}
                  >
                    <HiX size={19} />
                  </button>
                  <div className="flex flex-col justify-center items-center w-full h-full">
                    <span className=" text-2xl md:text-4xl font-extrabold text-neutral-200">
                      Publicité
                    </span>{' '}
                    <img
                      className={`w-[50px] md:w-[80px] relative -right-6 md:-right-8 transition-all duration-300 `}
                      src="/media/tayara-logo.svg"
                      alt="Logo de tayara.tn"
                    />
                  </div>
                </div>
              </>

              {loadHeavyContent ? <HorizontalBannerAd web handset /> : <></>}
            </div>
          ) : (
            // </div>
            <></>
          )}
        </>

        <CategoriesCarousel />

        <div
          className={`w-full h-full flex  justify-start items-center gap-2 mt-5 ${
            windowSize.width < 1280 && windowSize.width > 0 && 'flex-wrap'
          }`}
        >
          <div className="min-w-[280px] w-full lg:w-[400px] grow z-50">
            <GoldenClassified goldenClassified={goldenListing} />
          </div>

          <TayaraProductsSection />
        </div>

        {FEATUREDSHOPOFWEEK.map((carousel) => (
          <ShowcasedShopOfWeekCarousel
            key={carousel.shopOfMonthId}
            ShopOfMonthCarouselMetadata={carousel}
            hitSsr={props[carousel.ssrTag] as IListingItem[]}
          />
        ))}

        {/* {FEATUREDSECONDSHOPOFWEEK.map((carousel) => (
          <ShowcasedShopOfWeekCarousel
            key={shopOfWeekId}
            ShopOfMonthCarouselMetadata={carousel}
            hitSsr={props[carousel.ssrTag] as IListingItem[]}
          />
        ))} */}

        <div className="w-full flex flex-col mt-5 lg:mt-5">
          <div className="w-full gap-x-28 flex flex-wrap justify-center items-center md:justify-between mb-10 lg:mt-0 gap-y-4">
            <h2 className=" text-lg md:text-xl font-extrabold lg:truncate text-center">
              Découvrez les biens immobiliers neufs
            </h2>
            <Link
              href={`/immoNeuf`}
              passHref
              className="flex items-center gap-3 text-sm lg:text-base font-bold pl-3 md:ml-10 lg:mx-0 truncate text-neutral-800 underline"
            >
              Afficher plus <HiArrowNarrowRight size={20} />
            </Link>
          </div>
          <ImmoxSection />
        </div>

        <SubcategoriesSection />
      </div>
      <div
        className="flex flex-col px-2  gap-y-10 lg:gap-y-20 justify-center items-center w-full h-full bg-cover bg-no-repeat lg:mt-28  bg-top"
        style={{
          backgroundImage: `url(/media/appsectionbg.png)`,
        }}
      >
        <div className="w-full h-full flex flex-col-reverse lg:flex-col  justify-center items-center mt-40">
          <div className="relative w-full h-full  flex flex-col justify-center items-center mt-16">
            <div
              className={`lg:absolute w-full h-full top-10 -right-10 flex flex-col justify-center items-center `}
            >
              <span className="text-lg md:text-xl lg:text-2xl uppercase font-extrabold text-center">
                ACHETEZ ET VENDEZ PARTOUT!
              </span>
              <span className="text-sm md:text-lg lg:text-xl font-extrabold text-center mt-1 mb-4">
                Téléchargez notre application sur Android et iOS
              </span>
              <div className="flex flex-wrap justify-center items-center gap-2">
                <a
                  className="w-[150px] lg:w-[180px]"
                  href="https://play.google.com/store/apps/details?id=com.schibsted.android.rocket.tayara&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    loading="lazy"
                    alt="tayara - Available on Google Play"
                    src="/media/google-play.webp"
                  />
                </a>
                <a
                  href="https://apps.apple.com/tn/app/tayara-annonces-en-tunisie/id1238214138?itsct=apps_box_badge&amp;itscg=30200"
                  target="_blank"
                  className="w-[150px] lg:w-[180px]"
                  rel="noreferrer"
                >
                  <img
                    loading="lazy"
                    src="/media/app-store.webp"
                    alt="tayara - Available on the App Store"
                  />
                </a>
                <a
                  className="w-[155px] lg:w-[180px]"
                  href="https://appgallery.huawei.com/app/C105449911"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    loading="lazy"
                    src="/media/huawei-gallery.webp"
                    alt="tayara - Available on Huawei AppGallery"
                  />
                </a>
              </div>
            </div>
          </div>
          <img
            className={` relative ${isLoading && 'scale-0'} ${
              windowSize.width < 600 ? 'scale-[2.5] left-[10rem] mb-10' : ''
            }`}
            src="/media/tayaraapp.png"
            alt=""
          />
        </div>
        <div className="flex max-w-[1080px] text-center lg:text-start md:mx-auto text-xs md:text-sm mb-20">
          <p className="px-10">
            Avec <DescriptionLink href="/">Tayara</DescriptionLink>, trouvez la
            bonne affaire sur le site référent de petites annonces de
            particulier à particulier et de professionnels. Avec des millions de
            petites annonces, trouvez la bonne occasion dans nos catégories{' '}
            {linkData.map((link, index) => (
              <React.Fragment key={index}>
                <DescriptionLink href={link.href}>{link.text}</DescriptionLink>
                {index !== linkData.length - 1 && ', '}
              </React.Fragment>
            ))}{' '}
            , et bien plus encore. Déposez une annonce gratuite en toute
            simplicité pour vendre, rechercher, donner vos biens de seconde main
            ou promouvoir vos services. Achetez en toute sécurité avec notre
            système de paiement en ligne.
          </p>
        </div>
      </div>
    </>
  );
};

export default Index;
// Dynamic layout configuration
Index.getLayout = function GetLayout(page: JSX.Element) {
  return (
    <Layout>
      <Layout.Wide>{page}</Layout.Wide>
    </Layout>
  );
};

Index.getInitialProps = async (_ctx: NextPageContext): Promise<Props> => {
  let searchedListingsAction: SearchedListingsAction | undefined;
  let goldenListing: IListingItem | null = null;
  let newHitsVechicule: IListingItem[] | null = null;
  let newHitsImmo: IListingItem[] | null = null;
  let newHitsInfo: IListingItem[] | null = null;

  const catVech = {
    categoryId: '60be84bc50ab95b45b08a094',

    categoryLabel: 'Vechicule',
  };
  const catInfo = {
    categoryId: '60be84bc50ab95b45b08a097',

    categoryLabel: 'Info',
  };
  const catImmo = {
    categoryId: '60be84bc50ab95b45b08a093',

    categoryLabel: 'Immo',
  };
  if (typeof window === 'undefined') {
    [searchedListingsAction, goldenListing] = await getSSRInitialListings(
      _ctx.query
    );

    const [
      searchRequestVechicule,
      searchRequestImmo,
      searchRequestInfo,
    ]: SearchRequestObject[] = [catVech, catImmo, catInfo].map((cat) => {
      const filterRequest: FilterRequestObject = {
        categoryId: cat.categoryId,
        subCategoryId: '',
        adParamsMap: {},
        rangeAdParamsMap: {},
        governorate: '',
        delegation: [],
        minPrice: 0,
        maxPrice: 0,
        productTypeList: [],
        level: 0,
        state: 2,
        userid: '',
      };
      const searchRequest: SearchRequestObject = {
        query: '',
        offset: 0,
        limit: 8,
        sort: 0,
        filter: filterRequest,
      };

      return searchRequest;
    });
    if (
      !isEmpty(searchRequestVechicule) &&
      !isEmpty(searchRequestImmo) &&
      !isEmpty(searchRequestInfo)
    )
      [[[newHitsVechicule]], [[newHitsImmo]], [[newHitsInfo]]] =
        await Promise.all([
          searchElasticApi(searchRequestVechicule!, false),
          searchElasticApi(searchRequestImmo!, false),
          searchElasticApi(searchRequestInfo!, false),
        ]);
  }
  return cleanObject({
    searchedListingsAction,
    goldenListing,
    vechiculeHits: newHitsVechicule,
    ImmoBilierHits: newHitsImmo,
    InfoHits: newHitsInfo,
  });
};
