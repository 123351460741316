import React, { useRef, useState } from 'react';

import { isEmpty, omit } from 'lodash-es';
import Link from 'next/link';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { useEffectOnce } from 'usehooks-ts';

import {
  FilterRequestObject,
  SearchRequestObject,
} from '@/api/elasticsearch/models';
import { searchElasticApi } from '@/api/fetch/rest-search-api';
import { CarouselEvents } from '@/modules/analytics/event-types';
import useRudderTrack from '@/modules/analytics/hooks/useRudderTrack';
import { CarouselType } from '@/modules/analytics/types';
import ClassifiedCard from '@/tayara-kit/ClassifiedCard/ClassifiedCard';
import ClassifiedCardSkel from '@/tayara-kit/Sekeletons/ClassifiedCardSkel';
import XCarousel from '@/tayara-kit/XCarousel';

import { PosterDesignElements } from './CarouselPoster';
import ShopOfMonthCarouselPoster from './ShopOfMonthCarouselPoster';
import { useElasticSearchKeyCategories } from '../../hooks/useElasticSearchServer';
import { IListingItem } from '../../types';

export interface ShopOfMonthCarouselMetadata extends PosterDesignElements {
  shopOfMonthId: string;
  shopOfWeekId: string;
  shopLabel: string;
  shopLink: string;
  ssrTag: string;
  title: string;
}

const ShowcasedShopOfWeekCarousel = ({
  ShopOfMonthCarouselMetadata,
  hitSsr,
}: {
  ShopOfMonthCarouselMetadata: ShopOfMonthCarouselMetadata;
  hitSsr: IListingItem[];
}) => {
  // const [handleCategoryClick] = useMeilisearchKeyCategories();
  const [handleCategoryClick] = useElasticSearchKeyCategories();
  const { rudderTrack, trackCarouselClassifiedClick } = useRudderTrack();

  const [isCompact, setIsCompact] = useState(false);
  const portalRef = useRef<HTMLDivElement>(null);

  const [scrollRef, setScrollRef] = useState<React.RefObject<HTMLDivElement>>({
    current: null,
  });
  const [showcasedShopOfMonthData, setShowcasedShopOfMonthCarouselData] =
    useState<IListingItem[]>();

  const handleNavigation = () => {
    handleCategoryClick(ShopOfMonthCarouselMetadata?.shopOfMonthId || '');
    rudderTrack(CarouselEvents.CLICK_SHOP_OF_MONTH, {
      carouselType: ShopOfMonthCarouselMetadata,
      carouselDetails: ShopOfMonthCarouselMetadata,
    });
  };

  useEffectOnce(() => {
    if (isEmpty(hitSsr)) {
      const filterRequest: FilterRequestObject = {
        categoryId: '',
        subCategoryId: '',
        adParamsMap: {},
        rangeAdParamsMap: {},
        governorate: '',
        delegation: [''],
        minPrice: 0,
        maxPrice: 0,
        productTypeList: [],
        level: 0,
        state: 2,
        userid: ShopOfMonthCarouselMetadata?.shopOfMonthId,
      };
      const searchRequest: SearchRequestObject = {
        query: '',
        offset: 0,
        limit: 50,
        sort: 0,
        filter: filterRequest,
      };

      searchElasticApi(searchRequest, false).then(([[newHits, _]]) => {
        setShowcasedShopOfMonthCarouselData(newHits);
      });
    } else setShowcasedShopOfMonthCarouselData(hitSsr);
  });

  return (
    <div
      className="w-full mx-auto relative flex flex-col md:flex-row lg:flex-row gap-0 md:gap-10 lg:gap-6 lg:mt-10 items-center mt-5"
      ref={portalRef}
    >
      {/* TODO ADD A MARGIN LEFT */}
      {/* TODO FIX THE PERSPECTIVE */}
      {
        <ShopOfMonthCarouselPoster
          shopLink={ShopOfMonthCarouselMetadata?.shopLink}
          handleNavigation={handleNavigation}
          scrollRef={scrollRef}
          isCompact={isCompact}
          posterDesignElements={omit(ShopOfMonthCarouselMetadata, [
            'shopOfMonthId',
          ])}
        />
      }
      {/* A la une carousel */}
      <div className="overflow-x-hidden h-full w-full py-auto mb-5">
        <div className="w-full gap-x-28 flex flex-wrap justify-center md:justify-between mt-8 lg:mt-0 gap-y-4 mb-5">
          <h2 className=" text-lg md:text-xl font-extrabold truncate">
            {ShopOfMonthCarouselMetadata?.title}
          </h2>
          <Link
            href={`${ShopOfMonthCarouselMetadata?.shopLink}`}
            passHref
            onClick={handleNavigation}
            className="flex items-center gap-3 text-sm lg:text-base font-bold pl-3 md:ml-10 lg:mx-0 truncate text-neutral-800 underline"
          >
            Afficher tout <HiArrowNarrowRight size={20} />
          </Link>
        </div>

        <XCarousel
          setScrollRef={setScrollRef}
          portalEl={portalRef.current as Element}
          setIsCompact={setIsCompact}
          isCompact={isCompact}
        >
          <ul className="h-full flex gap-4 pt-4">
            {showcasedShopOfMonthData ? (
              showcasedShopOfMonthData.slice(0, 11).map((classified, index) => (
                <li
                  className="snap-start scroll-ml-px w-full"
                  key={classified.id}
                >
                  <ClassifiedCard
                    onClick={() =>
                      trackCarouselClassifiedClick(
                        CarouselEvents.CLICK_SHOP_OF_MONTH,
                        {
                          carousel_type: CarouselType.SHOP_OF_MONTH,
                          classified_details: classified,
                          classified_position: index,
                        }
                      )
                    }
                    classifiedCardData={classified}
                  />
                </li>
              ))
            ) : (
              <>
                <li>
                  <ClassifiedCardSkel />
                </li>
                <li>
                  <ClassifiedCardSkel />
                </li>
                <li>
                  <ClassifiedCardSkel />
                </li>
                <li>
                  <ClassifiedCardSkel />
                </li>
                <li>
                  <ClassifiedCardSkel />
                </li>
                <li>
                  <ClassifiedCardSkel />
                </li>
                <li>
                  <ClassifiedCardSkel />
                </li>
                <li>
                  <ClassifiedCardSkel />
                </li>
              </>
            )}
          </ul>
        </XCarousel>
      </div>
    </div>
  );
};

export default ShowcasedShopOfWeekCarousel;
